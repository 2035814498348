import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Testimonials.module.css';

import Image from '../../../../elements/Image';
import Container from '../../../../blocks/Container';

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/reviews.md/" } }) {
        edges {
          node {
            frontmatter {
              testimonials {
                image
                author
                occupation
                text
              }
            }
          }
        }
      }
    }
  `);

  const { testimonials } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.testimonials}>
      <div className={styles.testimonialsContent}>
        <Container>
          <Container type="mobile">
            {
              testimonials.map((testimonial, index) => (
                <div key={index} className={styles.testimonial}>
                  <Image name={testimonial.image} className={styles.testimonial__image} />
                  <div className="d-flex flex-column w-100">
                    <div className={`${styles.testimonial__text} mb-xl-4 mb-lg-4 mb-md-4 mb-3`}>
                      "{testimonial.text}”
                    </div>
                    <div className={styles.testimonial__author}>{testimonial.author}</div>
                    <div className={styles.testimonial__occupation}>{testimonial.occupation}</div>
                  </div>
                </div>
              ))
            }
          </Container>
        </Container>
      </div>
    </div>
  )
};

export default Testimonials;