import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './BlueprintsListContent.module.css';

import Container from '../../../../blocks/Container';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';
import LearnMoreLink from '../../../../elements/LearnMoreLink';
import IntegrationsBlock from '../../../../blocks/IntegrationsBlock';

const BlueprintsListContent = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blueprints-list.md/" } }) {
        edges {
          node {
            frontmatter {
              blueprints {
                name
                featured
                title
                description
                image
                imageBig
              }
            }
          }
        }
      }
    }
  `);

  const { blueprints } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const renderBlueprint = (blueprint, index) => (
    <div key={index} className="col-xl-6 col-lg-6 col-12">
      <a
        href={blueprint.name}
        className={`${styles.blueprintContainer} ${index % 2 !== 0 ? styles.blueprintContainerRight : ''}`}
      >
        <Image name={blueprint.image} className={styles.blueprintContainer__image} />
        <div className={styles.blueprintContainer__separator} />
        <div className={`${styles.blueprintContainer__title} mb-xl-2 mb-lg-2 mb-md-2 mb-3`}>
          {blueprint.title}
        </div>
        <Text className={`${styles.blueprintContainer__text} mb-4`}>{blueprint.description}</Text>
        <LearnMoreLink href={blueprint.name} className={styles.blueprintContainer__link} />
      </a>
    </div>
  );

  const renderCategoryHeader = (imageName, categoryName) => (
    <div className="row">
      <div className="col-12">
        <div className={styles.blueprintContainer__featuredMarkerContainer}>
          <Image name={imageName} className={styles.blueprintContainer__featuredMarker} />
          {categoryName}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.blueprintsListContent}>
      <Container type="mobile">
        <Container>
          {renderCategoryHeader('featured_marker', 'Featured Blueprints')}
          <div className="row">
            {
              blueprints.filter(blueprint => blueprint.featured).map(renderBlueprint)
            }
          </div>
          {renderCategoryHeader('other_marker', 'Other Blueprints')}
          <div className="row">
            {
              blueprints.filter(blueprint => !blueprint.featured).map(renderBlueprint)
            }
          </div>
        </Container>
        <IntegrationsBlock type="blue" thinLearnMore={true} className={styles.blueprintContainer__integrationsBlock} />
      </Container>
    </div>
  )
};

export default BlueprintsListContent;