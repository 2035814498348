import React, { Fragment } from 'react';

import BlueprintsListHeader from './BlueprintsListHeader';
import BlueprintsListContent from './BlueprintsListContent';
import Testimonials from './Testimonials';

const BlueprintsList = () => (
  <Fragment>
    <BlueprintsListHeader />
    <BlueprintsListContent />
    <Testimonials />
  </Fragment>
);

export default BlueprintsList;