import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import BlueprintsList from '../components/slides/blueprints/BlueprintsList';

const Blueprints = () => {
  return (
    <Layout>
      <SEO
        title="Popular Evergreen Marketing Blueprints | Deadline Funnel"
        description="All your favorite landing page and email platforms, integrated with Deadline Funnel."
      />
      <BlueprintsList />
    </Layout>
  )
};

export default Blueprints;