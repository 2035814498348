import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import styles from './BlueprintsListHeader.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Button from '../../../../elements/Button';
import Image from '../../../../elements/Image';
import WistiaEmbed from '../../../../elements/WistiaEmbed';

const BlueprintsListHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blueprints-header.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
            }
          }
        }
      }
    }
  `);

  const { title, subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.blueprintsListHeader}>
      <Container>
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-12 pl-xl-1 pl-lg-1 pr-xl-0 pr-lg-0">
              <Title className="white-text text-left p-xl-0 p-lg-0 p-md-2 p-0 mb-xl-4 mb-lg-4 mb-md-4 mb-4">
                {title}
              </Title>
              <Text className={`${styles.blueprintsListHeader__text} white-text p-xl-0 p-lg-0 p-md-2 p-0 mb-xl-5 mb-lg-5 mb-md-4`}>
                {subtitle}
              </Text>
              <div className="d-flex justify-content-xl-start justify-content-lg-start justify-content-center align-items-center flex-wrap">
                <Button
                  type="action"
                  className="mr-xl-3 mr-lg-3 mr-md-3 mr-0 mb-xl-0 mb-lg-0 mb-md-0 mb-2"
                  href="https://start.deadlinefunnel.com/create-monthly"
                >
                  Try It Free
                </Button>
                <div className="m-xl-0 m-lg-0 mt-3 mb-3" style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                  <span className="mr-3">Or</span>
                  <FontAwesomeIcon
                    className={styles.blueprintsListHeader__watchVideoImage}
                    icon={faPlay}
                    style={{ maxWidth: '10px', maxHeight: '10px' }}
                  />
                  <WistiaEmbed hashedId="f1uoi8zj3g">
                    <a className={styles.blueprintsListHeader__link} href="/">Watch Demo Video</a>
                  </WistiaEmbed>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
              <Image name="header_pic" className={styles.blueprintsListHeader__image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default BlueprintsListHeader;